import ahoy from "ahoy.js";

// Configure Ahoy
ahoy.configure({
  urlPrefix: "",
  visitsUrl: "/engage_trends/visits",
  eventsUrl: "/engage_trends/events",
  page: null,
  platform: "Web",
  useBeacon: true,
  startOnReady: true,
  trackVisits: true,
  cookies: true,
  cookieDomain: null,
  headers: {},
  visitParams: {},
  withCredentials: false,
  visitDuration: 4 * 60, // 4 hours
  visitorDuration: 2 * 365 * 24 * 60, // 2 years
});

export default ahoy;
