import ahoy from "./ahoy";

let debounceTimer;
const debounceDelay = 1000;

ahoy.trackClicks("a:not([data-no-track], button:not([data-no-track])");

document.addEventListener("turbo:load", function () {
  const location = window.location;

  const noTrackPage =
    location.pathname.includes("/admin") ||
    document.querySelector('[data-page-no-track="true"]') !== null;

  if (!noTrackPage) {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(function () {
      ahoy.trackView({
        pathname: location.pathname,
        hostname: location.hostname,
      });
    }, debounceDelay);
  }
});
